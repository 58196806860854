* {
  max-width: 100vw;
}

html {
  height:100%;
  width:100%
}

body {
  font-family: 'scrolltext';
  /* background-color: #373030e0; */
  /* background-color: #312b41; */
  background-color: #15151c;

  color: #ffffef;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  height: 100%;
  position: relative;
}

body::before {
  content: "";
  /* background-image: url(./assets/BG-TILE-2.webp), url(./assets/BG-TILE.webp); */
  background-size: 55vmin;
  background-repeat: repeat;
  opacity: 0.2;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

h1 {
  text-align: center;
  margin: 1rem;
}

a {
  color: #ffffef;
  text-decoration: none;
  font-size: 2rem;
  text-align: center;
}

a:hover {
  color: #ffa0d0;
  fill: #ffa0d0;
  /* -webkit-text-stroke: 1px #ffffff70; */
  /* paint-order: stroke fill; */
}

p {
  line-height: 1.4;
}

ul {
  margin: auto;
  list-style-type: circle;
}

button {
  cursor: pointer;
}

.tac {
  text-align: center;
}

.tal {
  text-align: left;
}

@media screen and (max-width: 600px) {
  * {
    max-width: 100vw;
  }
}

/* @font-face {
  font-family: "logotext"; 
  src: url("./assets/logotext.TTF");
  font-display: swap;
} */
@font-face {
  font-family: "scrolltext";
  src: url("./assets/scrolltext.otf");
  font-display: swap;
}